<template>
    <!-- view profile details -->
    <div id="user-profile">
        <!-- profile header -->
        <div class="row">
            <div class="col-12">
                <div class="card profile-header mb-2">
                    <!-- profile cover photo -->
                    <img class="card-img-top" src="../../assets/images/profile/user-uploads/timeline.jpg" alt="User Profile Image" />
                    <!--/ profile cover photo -->

                    <div class="position-relative">
                        <!-- profile picture -->
                        <div class="profile-img-container d-flex align-items-center">
                            <div class="profile-img">
                                <img src="../../assets/images/portrait/small/avatar-s-2.jpg" class="rounded img-fluid" alt="Card image" />
                            </div>
                            <!-- profile title -->
                            <div class="profile-title ms-3">
                                <h2 class="text-white">{{ applicantData.full_name }}</h2>
                                <p class="text-white">{{ applicantData.primary_role }}</p>
                            </div>
                        </div>
                    </div>

                    <!-- tabs pill -->
                    <div class="profile-header-nav">
                        <!-- navbar -->
                        <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">
                            <button class="btn btn-icon navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i data-feather="align-justify" class="font-medium-5"></i>
                            </button>

                        </nav>
                        <!--/ navbar -->
                    </div>

                    <!-- tabs pill -->
                    <div class="profile-header-nav">
                        <!-- navbar -->
                        <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">
                            <button class="btn btn-icon navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i data-feather="align-justify" class="font-medium-5"></i>
                            </button>


                        </nav>
                        <!--/ navbar -->
                    </div>
                </div>
            </div>
        </div>
        <!--/ profile header -->

        <!-- profile info section -->
        <section id="profile-info">
            <div class="row">

                <!-- center profile info section -->
                <div class="col-12 order-1 order-lg-2" v-if="applicantDataType === 'resume' ">
                    <base-card-plain >
                        <template #header>
                            <div class="profile-user-info">
                                <h4 class="mb-1">Profile Summary</h4>
                            </div>
                        </template>
                        <template #default>

                            <p class="card-text">
                                <span> {{ applicantData.bio.substr(0,520) }} </span>
                                <span v-if="!renderOptions.bio.overflow && applicantData.bio.length > 510"> .... </span>
                                <span v-if="renderOptions.bio.overflow"> {{ applicantData.bio.substr(520) }} </span>

                                <span v-if=" applicantData.bio.length > 510" class="cursor-pointer p-2 text-center"
                                      @click="renderOptions.bio.overflow = !renderOptions.bio.overflow">
                                         <b>
                                             <u>
                                                <i class="bi " :class="renderOptions.bio.overflow?'bi-dash-circle-fill':'bi-plus-circle-fill'"></i>
                                               {{ renderOptions.bio.overflow ? ' See less' : ' See more' }}
                                             </u>
                                         </b>
                                 </span>
                            </p>

                        </template>

                    </base-card-plain>

                    <base-card-plain >
                        <template #header>
                            <div class="profile-user-info metric-bg">
                                <h4 class="mb-0">Professional experience</h4>
                            </div>
                        </template>
                        <template #default>


                            <!-- start professional experience -->
                            <div class="d-flex align-items-start mb-1">



                                <div v-if="compWorkExperience.length > 0">
                                    <div v-for="(work, index) in compWorkExperience" :key="work.id" class="my-2">
                                        <div v-if="index > 1?renderOptions.work.overflow:true">
                                            <div class="pe-2">
                                                <i class="bi bi-arrow-right-circle-fill font-medium-5"></i>
                                                <b class="ms-1"> {{ work.title }} </b>
                                            </div>
                                            <div class="profile-user-info w-100">
                                                <div class="d-flex align-items-center justify-content-between">


                                                        {{ work.organization }}
                                                        ( {{ work.type.replace("_","-") }} )

                                                    <a href="#">
                                                        <span class="align-middle text-muted">
                                                            {{ monthList[JSON.parse(work.startDate).month] }},
                                                            {{ JSON.parse(work.startDate).year }}
                                                                        to
                                                            {{ JSON.parse(work.previousRole)?monthList[JSON.parse(work.endDate).month]+' - ':'date' }}
                                                            {{ JSON.parse(work.previousRole)?JSON.parse(work.endDate).year :''}}
                                                        </span>
                                                    </a>
                                                </div>
                                                <small>
                                                    {{ work.summary }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if=" compWorkExperience.length > 1 " class="text-center"
                                          @click="renderOptions.work.overflow = !renderOptions.work.overflow">
                                             <b>
                                                 <u class="cursor-pointer">
                                                    <i class="bi " :class="renderOptions.work.overflow?'bi-dash-circle-fill':'bi-plus-circle-fill'"></i>
                                                   {{ renderOptions.work.overflow ? ' See less' : ' See more' }}
                                                 </u>
                                             </b>
                                     </div>
                                </div>
                                <p class="text-center" v-else>
                                    Applicant is yet to record any work experience.
                                </p>
                            </div>
                            <!-- end professional experience -->

                        </template>

                    </base-card-plain>

                    <base-card-plain >
                        <template #header>
                            <div class="profile-user-info metric-bg">
                                <h4 class="mb-0">Accomplishments</h4>
                            </div>
                        </template>
                        <template #default>

                            <!-- start professional experience -->
                            <div class="d-flex align-items-start mb-1">


                                <div v-if="compAccomplishments.length > 0" class="w-100">
                                    <div v-for="( accomp , index ) in compAccomplishments" :key="accomp.id" class="my-2">
                                        <div v-if="index > 3?renderOptions.accomplishment.overflow:true">
                                            <div class="pe-2">
                                                <i class="bi bi-trophy-fill font-medium-5"></i>
                                                <b class="ms-1"> {{ accomp.name }} </b>
                                            </div>
                                            <div class="profile-user-info w-100">
                                                <div class="d-flex align-items-center justify-content-between">


                                                        {{ accomp.client }}
                                                        ( {{ accomp.type.replace("_", " / ") }} )

                                                    <a href="#">
                                                        <span class="align-middle text-muted">
                                                            <a :href="'http://'+accomp.link" target="_blank" >
                                                                <i class="bi bi-link-45deg pr-2"></i>
                                                            </a>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if=" compAccomplishments.length > 3 " class="text-center"
                                          @click="renderOptions.accomplishment.overflow = !renderOptions.accomplishment.overflow">
                                              <b>
                                                  <u class="cursor-pointer">
                                                     <i class="bi " :class="renderOptions.accomplishment.overflow?'bi-dash-circle-fill':'bi-plus-circle-fill'"></i>
                                                    {{ renderOptions.accomplishment.overflow ? ' See less' : ' See more' }}
                                                  </u>
                                              </b>
                                     </div>
                                </div>
                                <p class="text-center" v-else>
                                    Applicant is yet to record any accomplishment.
                                </p>
                            </div>
                            <!-- end professional experience -->

                        </template>

                    </base-card-plain>

                    <base-card-plain >
                        <template #header>
                            <div class="profile-user-info metric-bg" >
                                <h4 class="mb-0">Qualifications & expertise</h4>
                            </div>
                        </template>
                        <template #default>

                            <!-- start Education & Qualification -->
                            <div class="d-flex align-items-start mb-1">

                                <div v-if="compQualifications.length > 0"   class="w-100">

                                    <div v-for="category in compQualificationCategory" :key="category">
                                        <div v-if="compQualifications.filter((item)=>item.category == category)" >
                                            <div class="text-secondary" v-if="renderOptions.qualification.overflow">
                                                <u > <b> {{ category }} </b> </u>
                                            </div>

                                            <div v-for="(qualify, index) in compQualifications" :key="qualify.id" class="my-2">
                                                <div v-if="qualify.category == category && (index > 2?renderOptions.qualification.overflow:true)">
                                                    <div class="pe-2">
                                                        <i class="bi bi-arrow-right-circle-fill font-medium-5"></i>
                                                        <b class="ms-1"> {{ qualify.title }} </b> ({{ qualify.type }})
                                                    </div>
                                                    <div class="profile-user-info w-100">
                                                        <div class="d-flex align-items-center justify-content-between">


                                                                {{ qualify.institution }}


                                                            <a href="#" >
                                                                <span class="align-middle text-muted">
                                                                    {{ qualify.type == 'license' ? ' valid from ' : '' }}
                                                                    {{ qualify.startYear }}
                                                                        -
                                                                    {{  qualify.endYear }}
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div v-if=" compQualifications.length > 2 " class="cursor-pointer p-2 text-center col-12"
                                          @click="renderOptions.qualification.overflow = !renderOptions.qualification.overflow">
                                             <b>
                                                 <u class="cursor-pointer">
                                                    <i class="bi " :class="renderOptions.qualification.overflow?'bi-dash-circle-fill':'bi-plus-circle-fill'"></i>
                                                   {{ renderOptions.qualification.overflow ? ' See less' : ' See more' }}
                                                 </u>
                                             </b>
                                     </div>
                                </div>
                                <p class="qa-skill-box mt-5 py-4 text-center" v-else>
                                    Applicant is yet to record any qualification.

                                </p>

                            </div>
                            <!-- end Education & Qualification -->

                        </template>

                    </base-card-plain>

                    <base-card-plain >
                        <template #header>
                            <div class="profile-user-info metric-bg" >
                                <h4 class="mb-0">Skills & Expertisee</h4>
                            </div>
                        </template>
                        <template #default>

                            <!-- comments -->
                            <div class="d-flex align-items-start mb-1">
                                <div v-if="compSkills.length > 0">
                                    <div v-for="level in compSkillsLevel" :key="level">
                                        <div class="text-secondary">
                                            <u> <b> {{ level }} </b> </u>
                                        </div>
                                        <div class="my-2">
                                            <base-badge v-for="sk in compSkills.filter((item) => item.level === level)" :key="sk.id"  badgeColor="secondary">

                                                    {{ sk.skill }}

                                            </base-badge>
                                        </div>
                                    </div>
                                </div>
                                <p class="qa-skill-box mt-5 py-4 text-center" v-else>
                                    Applicant is yet to record any skill.
                                </p>
                            </div>
                            <!--/ comments -->

                        </template>

                    </base-card-plain>

                </div>
                <!--/ center profile info section -->


                <!-- start assessment section -->
                <div class="col-12 order-1 order-lg-2" v-if="applicantDataType == 'assessment' ">

                <base-card-plain >
                    <template #header>
                        <div class="profile-user-info metric-bg" >
                            <h4 class="mb-0">
                                Assessments
                            </h4>
                        </div>
                    </template>
                    <template #default>

                        <!-- comments -->
                            <recruitment-applicant-assessment
                                :applicantDataType="applicantDataType"
                                :applicantId="applicantData.ind_client_id"
                                :jobId="applicantData.job_id"
                                :assessmentList="assessmentList"
                                v-if="applicantDataType === 'assessment'"
                            >
                            </recruitment-applicant-assessment>
                        <!--/ comments -->

                    </template>

                    </base-card-plain>
                </div>
                <!--/ center profile info section -->



                <!-- start assessment section -->
                <div class="col-12 order-1 order-lg-2" v-if="applicantDataType == 'notes' ">

                <base-card-plain >
                    <template #header>
                        <div class="profile-user-info metric-bg" >
                            <h4 class="mb-0">Notes</h4>
                        </div>
                    </template>
                    <template #default>

                        <!-- comments -->
                            <recruitment-applicant-notes
                                :applicantNotes="applicantData.recruitment_notes?JSON.parse(applicantData.recruitment_notes):[]">
                            </recruitment-applicant-notes>
                        <!--/ comments -->

                    </template>

                    </base-card-plain>
                </div>
                <!--/ center profile info section -->

            </div>

        </section>
        <!--/ profile info section -->
    </div>
    <!-- view profile details -->
</template>

<script>
// import { useStore } from 'vuex';
import { reactive,computed } from 'vue'
import RecruitmentApplicantAssessment from '@/components/recruitment/RecruitmentApplicantAssessment.vue';
import RecruitmentApplicantNotes from '@/components/recruitment/RecruitmentApplicantNotes.vue';
export default{
    props:{
        applicantData:{
            type:Object,
            default:()=>{}
        },
        applicantDataType:{
            type:String,
            default:"resume"
        },
        assessmentList:{
            type:Object
        },
    },
    components:{
        RecruitmentApplicantAssessment,
        RecruitmentApplicantNotes
    },
    setup( props ){

        const renderOptions = reactive({
            bio:{overflow:false},
            work:{overflow:false},
            qualification:{overflow:false},
            accomplishment:{overflow:false},
            skill:{overflow:false}
        });

        console.log(props.applicantData)

        const compWorkExperience = computed(function(){
          let work = props.applicantData.work_experience?JSON.parse(props.applicantData.work_experience):[];
          return work;
        })

        const monthList = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const compAccomplishments = computed(function(){
          let accomplishments = props.applicantData.accomplishments?JSON.parse(props.applicantData.accomplishments):[];
          return accomplishments;
        })


        const compQualificationCategory = computed(function(){
          let category = [];

          let qualifications = props.applicantData.qualifications?JSON.parse(props.applicantData.qualifications):[];

          for(let i = 0; i < qualifications.length ; i++){
              category.push(qualifications[i]["category"]);
          }

          category = [...new Set(category)];

          return category.sort();

      });


        const compQualifications = computed(function(){

          let qualifications = props.applicantData.qualifications?JSON.parse(props.applicantData.qualifications):[];

          return qualifications;

      });


      const compSkillsLevel = computed(function(){
        let level = [];

        let skills = props.applicantData.skills?JSON.parse(props.applicantData.skills):[];

        for(let i = 0; i < skills.length ; i++){
            level.push(skills[i]["level"]);
        }

        level = [...new Set(level)];

        level.sort();

        level.reverse();

        return level;

    });


      const compSkills = computed(function(){
          let skills = props.applicantData.skills?JSON.parse(props.applicantData.skills):[];
          return skills;
      });




        return {
            renderOptions,
            monthList,
            compWorkExperience,
            compAccomplishments,
            compQualifications,
            compQualificationCategory,
            compSkills,
            compSkillsLevel
        }
    }
}
</script>

<style scoped>
.metric-bg{
    background-color:#f5f5f5;
    border-radius:5px;
    width:100%;
    padding:10px;
}
#user-profile {
  /*-------------profile header section---------*/
  /*-------- profile info section --------*/ }

#user-profile .profile-header {
  overflow: hidden; }

#user-profile .profile-header .profile-img-container {
  position: absolute;
  bottom: -2rem;
  left: 2.14rem;
  z-index: 2; }

#user-profile .profile-header .profile-img-container .profile-img {
  height: 8.92rem;
  width: 8.92rem;
  border: 0.357rem solid #fff;
  background-color: #fff;
  border-radius: 0.428rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1); }

#user-profile .profile-header .profile-header-nav .navbar {
  padding: 0.8rem 1rem; }

#user-profile .profile-header .profile-header-nav .navbar .navbar-toggler {
  line-height: 0; }

#user-profile .profile-header .profile-header-nav .navbar .profile-tabs .nav-item i,
#user-profile .profile-header .profile-header-nav .navbar .profile-tabs .nav-item svg {
  margin-right: 0; }

#user-profile #profile-info .profile-star {
  color: #babfc7; }

#user-profile #profile-info .profile-star i.profile-favorite,
#user-profile #profile-info .profile-star svg.profile-favorite {
  fill: #ff9f43;
  stroke: #ff9f43; }

#user-profile #profile-info .profile-likes {
  fill: #ea5455;
  stroke: #ea5455; }

#user-profile #profile-info .profile-polls-info .progress {
  height: 0.42rem; }

#user-profile .profile-latest-img {
  transition: all 0.2s ease-in-out; }

#user-profile .profile-latest-img:hover {
  transform: translateY(-4px) scale(1.2);
  z-index: 10; }

#user-profile .profile-latest-img img {
  margin-top: 1.28rem; }

#user-profile .block-element .spinner-border {
  border-width: 0.14rem; }

@media (max-width: 991.98px) {
  #user-profile .profile-latest-img img {
    width: 100%; } }

@media (min-width: 768px) {
  .profile-header-nav .profile-tabs {
    width: 100%;
    margin-left: 13.2rem; } }

@media (max-width: 575.98px) {
  #user-profile .profile-header .profile-img-container .profile-img {
    height: 100px;
    width: 100px; }
  #user-profile .profile-header .profile-img-container .profile-title h2 {
    font-size: 1.5rem; } }

</style>
