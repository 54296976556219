<!-- Timeline Starts -->
<template>
    <section class="basic-timeline">
        <div class="row">
            <div class="col-lg-12">
                <base-card-plain :showHeader="false" addBodyPadding='p-0'>
                    <template #default>

                        <ul class="timeline m-0">
                            <li class="timeline-item message-border my-2" v-for="(value,propertyName,index) in compActiveAssessmentList" :key="propertyName+index">
                                <span class="timeline-point timeline-point-secondary" style="left:1rem;">
                                        <i class="bi bi-file-earmark-check-fill"></i>
                                </span>
                                <div class="timeline-event ps-2">
                                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                        <h6>{{ value.alias.length > 16?value.alias.substr(0,14)+'..':value.alias }}</h6>
                                        <span class="timeline-event-time pe-0" v-if="activeApplicantGradeList(applicantId,propertyName).length > 0">
                                                <base-button
                                                class="me-1"
                                                    btnType="outline"
                                                    :btnColor="setApplicantGrade(applicantId,propertyName,'theme')"
                                                >
                                                   Score: {{ mutableAassessmentList[propertyName]['score'] && activeApplicantGradeList(applicantId,propertyName).length > 0?mutableAassessmentList[propertyName]['score']:setApplicantScore(applicantId,propertyName)}}
                                                </base-button>
                                                <base-button
                                                class="me-0"
                                                    :btnColor="setApplicantGrade(applicantId,propertyName,'theme')"
                                                >
                                                   {{ mutableAassessmentList[propertyName]['grade'] && activeApplicantGradeList(applicantId,propertyName).length > 0?mutableAassessmentList[propertyName]['grade']:setApplicantGrade(applicantId,propertyName,'level')}}
                                                </base-button>
                                        </span>
                                        <span class="timeline-event-time pe-1" v-else>
                                            Score & grade unavailable for this assessment
                                        </span>
                                    </div>
                                    <div v-if="activeApplicantGradeList(applicantId,propertyName).length > 0">
                                        <p class="cursor-pointer" @click="toggleAssessmentDetails(propertyName)">
                                            <u class="text-primary tiny-font">
                                                {{ mutableAassessmentList[propertyName]['action']?'Hide':'Show' }} assessment breakdown
                                            </u>
                                                <span class="text-secondary" v-if="mutableAassessmentList[propertyName]['action']">
                                                    ( <i class="bi bi-person-check-fill"></i>  Total graders : {{ totalGraders }} )
                                                </span>

                                        </p>
                                        <div class="d-flex flex-row align-items-center" v-if="mutableAassessmentList[propertyName]['action']">
                                            <table class="table table-bordered table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th class="tiny-td-col" >Grader</th>
                                                        <th class="tiny-td-col">Score</th>
                                                        <th class="qtr-td-col">Grade</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="">
                                                    <tr class="table-hover tiny-font" v-for="(assess,index) in activeApplicantGradeList(applicantId,propertyName)" :key="'key'+index">
                                                        <td>Grader-{{ parseInt(index)+1 }} </td>
                                                        <td>{{ assess.score }} </td>
                                                        <td>{{ assess.grade.replace("_"," ") }} </td>
                                                        <td>{{ assess.grade_notes}} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li class="pb-3 ps-1" v-if="false">
                                <base-pagination>
                                </base-pagination>
                            </li>
                        </ul>
                    </template>
                </base-card-plain>
            </div>
        </div>
    </section>
</template>
<!-- Timeline Ends -->

<script>

import { ref,inject,computed } from 'vue';

export default{

    props:{
        applicantDataType:{
            type:String,
            default:"assessment"
        },
        applicantId:{
            type:String,
            default:""
        },
        jobId:{
            type:String,
            default:""
        },
        assessmentList:{
            type:Object
        },

    },
    setup( props ){

        // get the axios object
        const axios = inject('axios');  // inject axios

        console.log(props.assessmentList)

        //get the urls
        const coreUrl = inject('coreUrl');

        const existingGradeData = ref(false);
        const applicantGradesList = ref([]);
        // use axios to get the grade data
        if(props.applicantDataType=='assessment'){

            const params = {
              job_id: props.jobId,
              applicant_id: props.applicantId,
              assessment: "all"
            }



            axios.get(
                coreUrl.backendUrl+'get_applicant_assessment_grade',
                {params, withCredentials: true}).then((response) =>{

                existingGradeData.value = true;
                applicantGradesList.value = response.data.data;


            }).catch((error)=>{
                    let errorMsg = error.response.data.message.req_msg?error.response.data.message.req_msg:"Your grade could not be retrieved at this time"
                    existingGradeData.value = false;
                    console.log(errorMsg)
            })
        }

        function activeApplicantGradeList(client_id, phase){
            return applicantGradesList.value.filter((item) => item.ind_client_id === client_id && item.assessment === phase)
        }


        const totalGraders = ref(1);

        function setApplicantScore(client_id, phase){
            let currGradeList = applicantGradesList.value.filter((item) => item.ind_client_id === client_id && item.assessment === phase)

            let i = 0
            let total = 0

            while(i < currGradeList.length ){
                total = total + currGradeList[i]['score']
                i++;
            }

            mutableAassessmentList.value[phase]['score'] = total/totalGraders.value

            return mutableAassessmentList.value[phase]['score'];

        }

        function setApplicantGrade(applicantId,phase,type){
            let score = mutableAassessmentList.value[phase]['score']?mutableAassessmentList.value[phase]['score']:setApplicantScore(applicantId,phase)
            if(score >= 80 && score <= 100){
                return type=='level'?'Excellent':'success'
            }else if(score >= 60  && score <= 79){
                return type=='level'?'Very good':'primary'
            }else if(score >= 40 && score <= 59){
                return type=='level'?'Satisfactory':'secondary'
            }else if(score >= 20 && score <= 39){
                return type=='level'?'Poor':'warning'
            }else if(score >= 0 && score <= 19){
                return type=='level'?'Very poor':'danger'
            }else{
                return type=='level'?'Unavailable':'dark';
            }
        }




        const compActiveAssessmentList = computed(function(){

            return Object.fromEntries(Object.entries(props.assessmentList).filter(([key]) => key !== 'recommendation' && key !== 'talent_pool' && key !== 'successful'));
        })

        const mutableAassessmentList = ref(compActiveAssessmentList.value)


        function toggleAssessmentDetails(prop){
            mutableAassessmentList.value[prop]['action'] = !mutableAassessmentList.value[prop]['action']
        }




        return {
            existingGradeData,
            applicantGradesList,
            activeApplicantGradeList,
            compActiveAssessmentList,
            mutableAassessmentList,
            toggleAssessmentDetails,
            totalGraders,
            setApplicantScore,
            setApplicantGrade
        }


    }



}
</script>

<style scoped>
.message-border{
    border-left: 0px;
    border-bottom: 1px solid #ebe9f1;
}

.remove-edges{
    border-radius:0px;
}


.search-bar-border{
  border-style:solid;
  border-color:#ebe9f1;
  border-width:0px 0px 1px 0px;
}

.search-bar-active{
    border-color:#7367f0;
}
</style>
