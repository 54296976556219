<template>
        <div class="sidebar-wrapper">

            <div class="sidebar-menu-list ps-1">
                <div class="list-group list-group-messages">
                    <h6 class="section-label mt-2 mb-1" >Assessment Phases</h6>
                    <span v-for="(value,propertyName,index) in mainAssessmentList" :key="propertyName">
                        <h6 class="section-label mt-3 mb-1" v-if="index === Object.keys(mainAssessmentList).length - 2">
                            Qualified Applicants
                        </h6>
                        <a    href="#"
                             class="list-group-item list-group-item-action mb-1 ps-1"
                             :class="'text-'+value.theme+' '+value.active"
                             @click="switchAssessmentTab(propertyName)"
                        >
                            <i class="bi font-medium-3 me-50" :class="value.icon"></i>
                            <span class="align-middle">
                                {{ value.alias.length > 16?value.alias.substr(0,14)+'..':value.alias }}
                            </span>
                            <span class="badge rounded-pill float-end" style="margin-top:3px" :class="'badge-light-'+value.theme">{{ value.total }}</span>

                        </a>
                    </span>

                </div>
                <!-- <hr /> -->
                <h6 class="section-label mt-3 mb-1">Reports</h6>
                <div class="list-group list-group-labels p-0 m-0 ps-1">
                    <a href="#" class="list-group-item list-group-item-action text-dark">
                        <span class="bi bi-bar-chart-fill"></span>
                        Report & analysis
                    </a>
                    <a href="#" class="list-group-item list-group-item-action text-dark">
                        <span class="bi bi-journal-text"></span>
                        Notes
                    </a>

                </div>
            </div>



        </div>

        <div class="card-body d-flex justify-content-center">
            <button class="btn btn-primary btn-toggle-sidebar w-100"
                    data-bs-toggle="modal" data-bs-target="#add-new-sidebar"
                    @click="$emit('emitSetupEvent','finalize_recruitment','Finalize recruitment')"
            >
                <span class="align-middle">Recruitment Status</span>
            </button>
        </div>

</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';


export default{
    emits:['emitSetupEvent'],
    props:{
        assessmentList:{
            type:Object
        }
    },
    setup(props){

        // get the global vuex store values
        const store = useStore();

        const mainAssessmentList = ref(props.assessmentList)

        function switchAssessmentTab(assessment){

            for(const item in mainAssessmentList.value){
                mainAssessmentList.value[item]['active'] = '';
            }

            mainAssessmentList.value[assessment]['active'] = 'active-tab';

            // use the dispatch function to call an action that calls a mutation
            store.dispatch("applicant/executeSetRecruitmentPhase",assessment);

        }


        return {
            mainAssessmentList,
            switchAssessmentTab
        }

    }
}
</script>

<style scoped>
.list-group-item{
    border:1px;
    padding-left:0.1em;
}

.active-tab
{
    border-left:1px solid #7367f0;
    border-radius:7px;

}

.list-group-labels{
    padding-left:1.5em;
}
</style>
