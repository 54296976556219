<!-- Timeline Starts -->
<template>
    <section class="basic-timeline">
        <div class="row">
            <div class="col-lg-12">
                <base-card-plain>
                    <template #header>
                        <h4 class="card-title">All Recruitment activity</h4>
                    </template>
                    <template #default>
                        <ul class="timeline">
                            <li class="timeline-item" v-for="(note,index) in applicantNotes" :key="note.step+index">
                                <span class="timeline-point">
                                    <i class="bi bi-megaphone"></i>
                                </span>
                                <div class="timeline-event">
                                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                        <h6>{{ note.step }}</h6>
                                        <span class="timeline-event-time"> {{ note.date?note.date:''}}</span>
                                    </div>
                                    <p>{{ note.note }}</p>
                                </div>
                            </li>
                        </ul>
                    </template>
                </base-card-plain>
            </div>
        </div>
    </section>
</template>
<!-- Timeline Ends -->

<script>
export default{
    props:{
        applicantNotes:{
            type:Array,
            default:()=>[]
        }
    },
    setup(props){
        console.log(props.applicantNotes)
    }
}

</script>
