<template>
    <base-card-plain :showHeader="false">
        <template #default>

            <form class="validate-form" @submit.prevent="updateApplicantStatus">

                <div class="row">



                    <div class="col-12"  v-if=" actionType=='rejected' ">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.rejectionNote.msg?'text-danger':''" for="rejectionNote">
                                Rejection note (optional)
                                <span v-if="fv.rejectionNote.msg" > | {{fv.rejectionNote.msg}}</span>
                            </label>
                            <textarea @keyup="validate.validateFormData(fv,'rejectionNote',true)" v-model="fv.rejectionNote.val"
                                class="form-control" id="rejectionNote"
                                rows="4" placeholder="Why are you rejecting the applicant">
                            </textarea>
                        </div>
                    </div>

                    <div class="col-12 col-lg-12 col-sm-12" v-if=" actionType=='change_step' ">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.changeStep.msg?'text-danger':''"  for="changeStep">
                                Change candidate recruitment phase
                                <span v-if="fv.changeStep.msg" > | {{fv.changeStep.msg}}</span>
                            </label>
                            <select class="form-select" id="changeStep"
                                    @change="checkNewPhase"
                                    v-model="fv.changeStep.val">
                                <option value="">Select</option>
                                <option v-for="(assess,index) in compAssessmentList"
                                        :key="assess.value" :value="assess.value+'::'+index">
                                    {{ assess.alias }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12"  v-if=" actionType=='change_step' ">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.changeStepNote.msg?'text-danger':''" for="changeStepNote">
                                Progress note (optional)
                                <span v-if="fv.changeStepNote.msg" > | {{fv.changeStepNote.msg}}</span>
                            </label>
                            <textarea @keyup="validate.validateFormData(fv,'changeStepNote',true)" v-model="fv.changeStepNote.val"
                                class="form-control" id="changeStepNote"
                                rows="4" placeholder="Why are you rejecting the applicant">
                            </textarea>
                        </div>
                    </div>

                    <div class="col-12 col-lg-12 col-sm-12" v-if=" actionType=='recommendation' " >
                        <div class="mb-1">
                            <label class="form-label" :class="fv.recommendation.msg?'text-danger':''"  for="recommend">
                                Recommend candidate for
                                <span v-if="fv.recommendation.msg" > | {{fv.recommendation.msg}}</span>
                            </label>
                            <select class="form-select" id="recommend"
                                    @keyup="validate.validateFormData(fv,'recommendation',true)"
                                    v-model="fv.recommendation.val">
                                <option value="">Select</option>
                                <option v-for="assess in compAssessmentList"
                                        :key="assess.value" :value="assess.value">
                                    {{ assess.alias }}
                                </option>
                            </select>
                        </div>
                    </div>


                    <div class="col-12 col-lg-12 col-sm-12" v-if=" actionType=='recommendation_request' " >
                        <div class="mb-1">
                            <label class="form-label" :class="fv.recommendationRequest.msg?'text-danger':''"  for="recommendation_request">
                                Do you wish to accept?
                                <span v-if="fv.recommendationRequest.msg" > | {{fv.recommendationRequest.msg}}</span>
                            </label>
                            <select class="form-select" id="recommendation_request"
                                    @keyup="validate.validateFormData(fv,'recommendation_request',true)"
                                    v-model="fv.recommendationRequest.val">
                                <option value="">Select</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                    </div>


                    <div class="col-12 col-lg-12 col-sm-12" v-if=" actionType=='grade' " >
                        <div class="mb-1">
                            <label class="form-label" :class="fv.evaluationType.msg?'text-danger':''"  for="evaluationType">
                                Evaluation type
                                <span v-if="fv.evaluationType.msg" > | {{fv.evaluationType.msg}}</span>
                            </label>
                            <select class="form-select" id="evaluationType"
                                    @keyup="validate.validateFormData(fv,'evaluationType',false)"
                                    v-model.trim="fv.evaluationType.val">
                                <option value="">Select</option>
                                <option value="scoring">Scoring (ideal for written evaluations)</option>
                                <option value="grade">Grade (ideal for interview and presentation evaluations)</option>
                            </select>
                        </div>
                    </div>


                    <div class="col-12 col-lg-12 col-sm-12" v-if=" actionType=='grade' && fv.evaluationType.val == 'grade' " >
                        <div class="mb-1">
                            <label class="form-label" :class="fv.grade.msg?'text-danger':''"  for="grade">
                                Grade
                                <span v-if="fv.grade.msg" > | {{ fv.grade.msg }}</span>
                            </label>
                            <select class="form-select" id="evaluationType"
                                    @keyup="validate.validateFormData(fv,'grade',false)"
                                    v-model="fv.grade.val">
                                <option value="">Select</option>
                                <option v-for="grade in gradeOptions" :key="grade.value" :value="grade.value">
                                    {{ grade.alias }}
                                </option>
                            </select>
                        </div>
                    </div>


                    <div class="col-12 col-sm-12" v-if=" actionType=='grade' && fv.evaluationType.val == 'scoring' " >
                        <div class="mb-1">
                            <label class="form-label" :class="fv.score.msg?'text-danger':''"  for="grade">
                                Enter score
                                <span v-if="fv.score.msg" > | {{fv.score.msg}}</span>
                            </label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control"  @keyup="validate.validateFormData(fv,'score',false)" v-model="fv.score.val"
                                  placeholder="Enter score ( as percentage )" id="grade"  />
                                 <span class="input-group-text" style="border-left:0px">%</span>
                            </div>
                        </div>
                    </div>


                    <div class="col-12" v-if=" actionType=='grade' " >
                        <div class="mb-1">
                            <label class="form-label" :class="fv.actionNote.msg?'text-danger':''" for="actionNote">
                                Additional {{ actionType }} feedback
                                <span v-if="fv.actionNote.msg" > | {{fv.actionNote.msg}}</span>
                            </label>
                            <textarea @keyup="validate.validateFormData(fv,'actionNote',false)" v-model="fv.actionNote.val"
                                class="form-control" id="actionNote"
                                rows="4" :placeholder="`Enter ${actionType} feedback`">
                            </textarea>
                        </div>
                    </div>


                    <div class="col-12"  v-if=" actionType=='progress_notification' ">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.inviteApplicant.msg?'text-danger':''" for="inviteApplicant">
                                Invitation (optional)
                                <span v-if="fv.inviteApplicant.msg" > | {{fv.inviteApplicant.msg}}</span>
                            </label>
                            <textarea @keyup="validate.validateFormData(fv,'inviteApplicant',true)" v-model="fv.inviteApplicant.val"
                                class="form-control" id="inviteApplicant"
                                rows="4" placeholder="Send an inviation to the applicant(s)">
                            </textarea>
                        </div>
                    </div>


                    <div class="col-12"  v-if=" actionType=='progress_notification' " >
                                <div class="mb-1">
                                    <label class="form-label"  :class="fv.accessmentDate.msg?'text-danger':''"  >
                                        Select accessment date / time? *
                                        <span v-if="fv.accessmentDate.msg" > | {{fv.accessmentDate.msg}}</span>
                                    </label>
                                    <div class="col-12 col-sm-12">
                                        <Datepicker :is24="false" v-model="fv.accessmentDate.val" :format="dateStringFormat">
                                        </Datepicker>
                                    </div>
                                </div>
                    </div>


                    <div class="col-12" v-if="fv.reqFeedback.msg">

                        <base-list-tag :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>





                    <div class="col-12" v-if="fv.showSubmit.status">
                        <base-button btnColor="primary" >Confirm {{ actionType.replace(/_/g,' ') }}</base-button>
                    </div>
                    <base-spinner  v-if="fv.showSubmit.val"></base-spinner>

                </div>

            </form>
        </template>
    </base-card-plain>
</template>

<script>
import { ref,reactive,inject,computed} from 'vue';
import { useStore } from 'vuex';
import validators from '@/hooks/validation';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';

export default{
    props:{
        appId:{
            default:"none",
            type: String
        },
        appDataSet:{
            default: ()=>[],
            type: Array
        },
        actionType:{
            default:"none",
            type: String
        },
        assessmentList:{
            type:Object
        }
    },
    components: { Datepicker },
    setup(props){

        // get the axios object
        const axios = inject('axios');  // inject axios

        // // get the global vuex store values
        const store = useStore();

        //get the urls
        const coreUrl = inject('coreUrl');

        // set the validators function
        const validate = validators();


        let defaultRejectionNote =  "Did not meet the minimum requirement to move to the next phase";
        let defaultChangeStepNote =  "Met the necessary requirements to move to the next phase";


        const existingGradeData = ref(false);
        const existingGradeType = ref("");
        const existingGrade = ref("");
        const existingGradeScore = ref("");
        const existingGradeNotes = ref("");

        // form values to be used in this form
        const fv = reactive({
            rejectionNote: {status:false, val:defaultRejectionNote, msg:false, validate:["required"]},
            changeStep: {status:false, val:"", msg:false, validate:["required"]},
            changeStepAction: {status:false, val:'progress', msg:false, validate:["required"]},
            changeStepNote: {status:false, val:defaultChangeStepNote, msg:false, validate:["required"]},
            accessmentDate: {status:false, val:"", msg:false, validate:["required"]},
            recommendation: {status:false, val:"", msg:false, validate:["required"]},
            recommendationRequest: {status:false, val:"", msg:false, validate:["required"]},
            evaluationType: {status:false, val:existingGradeType, msg:false, validate:["required"]},
            grade: {status:false, val:existingGrade, msg:false, validate:["required"]},
            score: {status:false, val:existingGradeScore, msg:false, validate:["decimal",
                                                               {option:"numericMax",val:100},
                                                               {option:"numericMin",val:0}
                                                           ]},
            actionNote: {status:false, val:existingGradeNotes, msg:false, validate:["required"]},
            inviteApplicant: {status:false, val:"", msg:false, validate:["required"]},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:true, val:false}
        });

        const gradeOptions = ref([
            {value:'excellent' , alias:'Excellent'},
            {value:'very_good' , alias:'Very good'},
            {value:'satisfactory' , alias:'Satisfactory'},
            {value:'poor' , alias:'Poor'},
            {value:'very_poor' , alias:'Very poor'}
        ])

        const gradeValues = {
            excellent:99,
            very_good:79,
            satisfactory:59,
            poor:39,
            very_poor:19
        }


        function getGrade(grade){

            if(grade >= 80 && grade <= 100){
                return 'excellent'
            }else if(grade >= 60  && grade <= 79){
                return 'very_good'
            }else if(grade >= 40 && grade <= 59){
                return 'satisfactory'
            }else if(grade >= 20 && grade <= 39){
                return 'poor'
            }else if(grade >= 0 && grade <= 19){
                return 'very_poor'
            }else{
                return false;
            }

        }



        let activePhase = store.getters["applicant/getRecruitmentPhase"];

        let assessmentSize = Object.keys(props.assessmentList).length

        const compAssessmentList = computed(function(){
            let finalList = []

            let j = 1;
            let skipIndex = 0;

            for(let i in props.assessmentList){

                if(j === skipIndex){
                    break;
                }

                if( i !== activePhase && i !== 'recommendation' && i !== 'talent_pool' && i !== 'successful'){
                    finalList.push({value:i,alias:props.assessmentList[i]['alias']})
                }

                if(j === assessmentSize){
                    break;
                }

                j++;

                if(i === activePhase ){
                    skipIndex = j+1
                }




            }

            return finalList
        })

        const monthList = [ "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];


        const dateStringFormat = (newDate) => {
            const minute = newDate.getMinutes();
            const hour = newDate.getHours();
            const day = newDate.getDate();
            const month = newDate.getMonth() + 1;
            const year = newDate.getFullYear();

            const period = hour > 12 ? "pm" : "am";

            fv.accessmentDate.val = `${year}-${month}-${day} ${hour}:${minute}:30.139763`;

            return `Assessment scheduled for ${hour}:${minute} ${period} on ${day}-${monthList[month -1]}-${year}`;
        }


        function checkNewPhase(){
            validate.validateFormData(fv,'changeStep',false)
            let newPhase = fv.changeStep.val
            let phaseArray = newPhase.split('::');

            if(phaseArray[1] < +compAssessmentList.value.length - 1){
                fv.reqFeedback.msg = "You are moving candidate to a phase lower than the current level. kindly note that candidate will be automatically rejected";
                fv.reqFeedback.val = "warning";
                fv.changeStepAction.val = "reject";
                fv.changeStepNote.val = defaultRejectionNote;
            }else{
                fv.reqFeedback.msg = "";
                fv.reqFeedback.val = "";
                fv.changeStepAction.val = "progress";
                fv.changeStepNote.val = defaultChangeStepNote;
            }
        }



        // use axios to get the grade data
        if(props.actionType=='grade'){
            const params = {
              job_id: props.appDataSet[0]["job_id"],
              applicant_id: props.appDataSet[0]["ind_id"],
              assessment: props.appDataSet[0]["assessment"]
            }

            axios.get(
                coreUrl.backendUrl+'get_applicant_assessment_grade',
                {params, withCredentials: true}).then((response) =>{

                existingGradeData.value = true;
                const result = response.data.data;
                existingGradeType.value = result.grade_type
                existingGrade.value = result.grade
                existingGradeScore.value = result.score
                existingGradeNotes.value = result.grade_notes

            }).catch((error)=>{
                    let errorMsg = error.response.data.message.req_msg?error.response.data.message.req_msg:"Your grade could not be retrieved at this time"
                    existingGradeData.value = false;
                    console.log(errorMsg)
            })
        }



        // start create  JD
        async function updateApplicantStatus(){

            // start the action of making a submission, and show loader
            fv.showSubmit.status = false;
            fv.showSubmit.val = true;

            // load the form data , and append all the necessary fields
            let formData = new FormData();
            formData.append("fv_appDataSet", JSON.stringify(props.appDataSet));

            if(props.actionType == 'rejected' || props.actionType == 'reset'){
               formData.append("fv_rejectionNote", fv.rejectionNote.val);
            }

            if(props.actionType=='change_step'){
                formData.append("fv_changeStep", fv.changeStep.val);
                formData.append("fv_changeStepAction", fv.changeStepAction.val);
                formData.append("fv_changeStepNote", fv.changeStepNote.val);
            }

            if(props.actionType=='recommendation'){
                formData.append("fv_recommendation", fv.recommendation.val);
            }

            if(props.actionType=='recommendation_request'){
                formData.append("fv_recommendationRequest", fv.recommendationRequest.val);
            }

            if(props.actionType=='grade'){
                formData.append("fv_grade", fv.evaluationType.val == 'grade'?fv.grade.val:getGrade(fv.score.val))
                formData.append("fv_score", fv.evaluationType.val == 'scoring'?fv.score.val:gradeValues[fv.grade.val])
                formData.append("fv_gradeType", fv.evaluationType.val)
                formData.append("fv_actionNote", fv.actionNote.val);
            }




            // post the data using axios
            try{

                const urlOptions = {
                    rejected:'update_applicant_rejection',
                    reset:'update_applicant_rejection',
                    change_step:'update_applicant_step',
                    recommendation:'update_applicant_recommendation',
                    recommendation_request:'update_applicant_recommendation_request',
                    grade:'update_applicant_grade',
                    successful:'update_applicant_qualified',
                    talent_pool:'update_applicant_qualified',
                    rejection_notification:'update_applicant_rejection_notification',
                    talent_pool_notification:'update_applicant_talent_pool_notification',
                    success_notification:'update_applicant_success_notification'
                }

                const response = await axios.put(
                    coreUrl.backendUrl+urlOptions[props.actionType],
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

                // If successful, get the resppnse data
                const responseData = response.data;


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = responseData.message.req_msg;
                fv.reqFeedback.val = "success";

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = false;

                // update applicant list in vue store
                const storeApplicantList = await ref(store.getters["applicant/getApplicantList"]);

                if(storeApplicantList.value){
                    for(let i = 0; i < props.appDataSet.length ; i++){
                        for(let j = 0; j < storeApplicantList.value.length ; j++){
                            if(props.appDataSet[i]['id'] == storeApplicantList.value[j]['id'] ){

                                // update applicant list store for rejected
                                if(props.actionType == 'rejected' ){
                                    storeApplicantList.value[j]['final_step_status'] = 'rejected';
                                }

                                if(props.actionType == 'reset' ){
                                    storeApplicantList.value[j]['final_step_status'] = 'review_pending';
                                    storeApplicantList.value[j]['inform_status'] = 'uninformed';
                                }

                                if(props.actionType=='change_step'){
                                    console.log(fv.changeStepAction.val)
                                    storeApplicantList.value[j]['step'] = fv.changeStep.val.split('::')[0];
                                    storeApplicantList.value[j]['final_step_status'] = fv.changeStepAction.val === 'reject'?'rejected':'review_pending';
                                }

                                if(props.actionType=='recommendation'){
                                    storeApplicantList.value[j]['step'] = 'recommendation';
                                    storeApplicantList.value[j]['recommendation'] = fv.recommendation.val;
                                    storeApplicantList.value[j]['last_step'] = store.getters["applicant/getRecruitmentPhase"];
                                    storeApplicantList.value[j]['final_step_status'] = 'approval_pending';
                                }

                                if(props.actionType=='recommendation_request'){
                                    storeApplicantList.value[j]['step'] = fv.recommendationRequest.val == 'yes'?props.appDataSet[i]['recommendation']:props.appDataSet[i]['last_step'];
                                    storeApplicantList.value[j]['recommendation'] = '';
                                    storeApplicantList.value[j]['last_step'] = '';
                                    storeApplicantList.value[j]['final_step_status'] = 'review_pending';
                                }

                                if(props.actionType == 'successful' ){
                                    storeApplicantList.value[j]['step'] = 'successful';
                                    storeApplicantList.value[j]['final_step_status'] = 'acceptance_pending';
                                }

                                if(props.actionType == 'talent_pool' ){
                                    storeApplicantList.value[j]['step'] = 'talent_pool';
                                    storeApplicantList.value[j]['final_step_status'] = 'acceptance_pending';
                                }

                                if(props.actionType == 'rejection_notification' ){
                                    storeApplicantList.value[j]['inform_status'] = 'informed';
                                }

                                if(props.actionType == 'talent_pool_notification' ){
                                    storeApplicantList.value[j]['inform_status'] = 'informed';
                                }

                                if(props.actionType == 'success_notification' ){
                                    storeApplicantList.value[j]['inform_status'] = 'informed';
                                }

                            }
                        }
                    }
                }

                // store.dispatch("applicant/executeSetApplicantList",null);
                store.dispatch("applicant/executeSetApplicantList",storeApplicantList.value);


            }catch(error){
                // console.log(error)
                // load the error to the appropriate field
                const fv_msg = error.response.data.message.fv_msg;
                const req_msg = error.response.data.message.req_msg;

                for(const key in fv_msg){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = fv_msg[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = req_msg;
                fv.reqFeedback.val = "danger";

                // end the action of making a submission, and hide loader
                fv.showSubmit.status = true;
                fv.showSubmit.val = false;

            }

        }
        // end edit JD


        return {
            fv,
            validate,
            dateStringFormat,
            gradeOptions,
            compAssessmentList,
            checkNewPhase,
            updateApplicantStatus
        }

    }
}


</script>
