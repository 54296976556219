<template>
    <div class="row p-0 m-0"  v-if="applicantTotal > 0">
            <div class="input-group  p-0">
                <input type="text"
                       class="form-control remove-edges search-bar-border"
                       @keyup="searchApplicant"
                       v-model="searchValue"
                       placeholder="Search using name,country or city"
                 />

                <button class="btn btn-flat-secondary
                        remove-edges search-bar-border btn-toggle-sidebar "
                        type="button"
                        data-bs-toggle="modal" data-bs-target="#add-new-sidebar"
                        @click="$emit('emitSetupEvent','filter','Filter applicants')"
                >
                <span class="spinner-grow spinner-grow-sm text-primary" role="status" v-if="activeFilter">
                </span>
                <i class="bi bi-sliders bs-icon-btn"  v-else></i>
                    Filters
                </button>
            </div>

            <ul v-if="applicantList.length > 0">

                <li class="row"  style="background:#f5f5f5">
                    <div class="col-10" v-if="compRecruitmentStatus">
                            <div class="form-check">
                                <input type="checkbox"
                                       @click="selectAllApplicants"
                                       class="form-check-input"
                                       :checked="isSelectAll?'checked':null"
                                 />
                                <label class="orm-check-label" for="customCheck13">
                                    Select all
                                </label>
                            </div>

                    </div>
                    <div class="col-2" v-if="compRecruitmentStatus">
                        <small class="text-nowrap text-muted me-0" style="float:right" v-if="!singleAction">
                            <div class="dropdown">
                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                    <i class="bi bi-three-dots-vertical bs-icon-small" ></i> select
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">
                                        <i class="bi bi-hand-thumbs-down bs-icon-small" ></i>
                                        <span class="ps-1"> Reject applicant </span>
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        <i class="bi bi-hand-thumbs-up-fill bs-icon-small" ></i>
                                        <span class="ps-1">  Move to next phase </span>
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        <i class="bi bi-person-lines-fill bs-icon-small" ></i>
                                        <span class="ps-1">  Recommend </span>
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        <i class="bi bi-mortarboard-fill bs-icon-small" ></i>
                                        <span class="ps-1"> Grade </span>
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        <i class="bi bi-pencil-square bs-icon-small" ></i>
                                        <span class="ps-1"> Feedback </span>
                                    </a>
                                </div>
                            </div>
                        </small>
                    </div>
                </li>
                <li class="row" style="background:#e7ecf1;">
                    <div class="col-5" >
                            <div class="ps-1">
                                <b> Applicant name </b>
                            </div>

                    </div>
                    <div class="col-3 text-center"  >
                            <b> Review </b>
                    </div>
                    <div class="col-3 text-center"  >
                            <b> Final Status </b>
                    </div>
                    <div class="col-1" >
                            <b class="me-2" style="float:right">Action</b>
                    </div>
                </li>
                <li class="row " v-for="(app,index) in applicantList" :key="app.id">
                    <div class="col-5" >
                            <div class="form-check align-middle  pt-1">
                                <input type="checkbox"
                                       name="allApplicants[]"
                                       class="form-check-input"
                                       :id="'applicant-'+index"
                                       :value="app.id"
                                       :checked="isSelectAll?'checked':null"
                                       @click="toggleSingleAction"
                                       v-if="compRecruitmentStatus"
                                 />
                                <div  >
                                    {{ app.full_name}}
                                    {{ compRecommendationPhase?' ( move from ':'' }}
                                <b class='text-warning'> {{ compRecommendationPhase?`${assessmentList[app.last_step]['alias']}`:'' }} </b>
                                    {{ compRecommendationPhase?'to':'' }}
                                <b class='text-primary'> {{ compRecommendationPhase?`${assessmentList[app.recommendation]['alias']}`:'' }} </b>
                                    {{ compRecommendationPhase?' )':'' }}
                                </div>
                            </div>

                    </div>
                    <div class="col-3 text-center" >
                            <div class="btn btn-flat-secondary"
                                 data-bs-toggle="modal"
                                 data-bs-target="#applicantModal"
                                 @click="viewApplicantData(app.id,app,'resume')"
                            >
                                <i class="bi bi-file-earmark-person bs-icon-small"
                                    title="Applicant's resume">
                                </i>
                            </div>

                            <div class="btn btn-flat-secondary"
                                 data-bs-toggle="modal"
                                 data-bs-target="#applicantModal"
                                 @click="viewApplicantData(app.id,app,'assessment')"
                            >
                                <i class="bi bi-file-earmark-check bs-icon-small"
                                    title="Applicant assessment records">
                                </i>
                            </div>

                            <div class="btn btn-flat-secondary"
                                 data-bs-toggle="modal"
                                 data-bs-target="#applicantModal"
                                 @click="viewApplicantData(app.id,app,'notes')"
                            >
                                <i class="bi bi-journal-text bs-icon-small"
                                    title="Applicant's progress notes">
                                </i>
                           </div>
                    </div>
                    <div class="col-3 text-center" >
                            <div class="btn btn-flat-secondary"
                                :class="app.final_step_status == 'rejected'?'btn-flat-danger':'btn-flat-secondary'"
                            >
                                <i class="bi " :class="app.inform_status == 'informed'?'bi-bell-fill':'bi-bell-slash' "></i> {{ app.final_step_status.replace("_"," ") }}
                            </div>
                    </div>
                    <div class="col-1" v-if="compRecruitmentStatus">
                        <small class="text-nowrap text-muted me-0 " style="float:right" v-if="singleAction">

                            <div class="dropdown">
                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                    <i class="bi bi-three-dots-vertical bs-icon-small" ></i> select
                                </button>
                                <div class="dropdown-menu">
                                    <a  v-if="compRecommendationPhase"
                                        class="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#actionModal"
                                        @click="changeApplicantProgress(app.id,'recommendation_request','Confirm recommendation request for '+app.full_name)"
                                    >
                                        <i class="bi bi-patch-question-fill bs-icon-small" ></i>
                                        <span class="ps-1"> Confirm request </span>
                                    </a>
                                    <a  v-if="compFinalPhase  && !compRecommendationPhase"
                                        class="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#actionModal"
                                        @click="changeApplicantProgress(app.id,'successful','Confirm succesful selection of '+app.full_name)"
                                    >
                                        <i class="bi bi-star-fill bs-icon-small" ></i>
                                        <span class="ps-1"> Successful applicant </span>
                                    </a>
                                    <a  v-if="compFinalPhase && !compRecommendationPhase"
                                        class="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#actionModal"
                                        @click="changeApplicantProgress(app.id,'talent_pool','Confirm addition of '+app.full_name+ ' to talent pool')"
                                    >
                                        <i class="bi bi-patch-check-fill bs-icon-small" ></i>
                                        <span class="ps-1"> Add to talent pool </span>
                                    </a>
                                    <a  v-if="app.final_step_status !== 'rejected'  && !compRecommendationPhase && compActivePhase !== 'talent_pool' && compActivePhase !== 'successful' "
                                        class="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#actionModal"
                                        @click="changeApplicantProgress(app.id,'rejected','Confirm rejection for '+app.full_name)"
                                    >
                                        <i class="bi bi-hand-thumbs-down bs-icon-small" ></i>
                                        <span class="ps-1"> Reject applicant </span>
                                    </a>
                                    <a  v-if="app.final_step_status === 'rejected'  && !compRecommendationPhase"
                                        class="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#actionModal"
                                        @click="changeApplicantProgress(app.id,'reset','Confirm reset for '+app.full_name)"
                                    >
                                        <i class="bi bi-recycle bs-icon-small" ></i>
                                        <span class="ps-1"> Reset </span>
                                    </a>
                                    <a  v-if="app.final_step_status !== 'rejected' && !compRecommendationPhase"
                                        class="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#actionModal"
                                        @click="changeApplicantProgress(app.id,'change_step','Confirm change for '+app.full_name)"
                                    >
                                        <i class="bi bi-arrows-move bs-icon-small" ></i>
                                        <span class="ps-1">  Move candidate </span>
                                    </a>
                                    <a  class="dropdown-item" v-if="!compRecommendationPhase"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#actionModal"
                                        @click="changeApplicantProgress(app.id,'recommendation','Confirm recommendation for '+app.full_name)"
                                    >
                                        <i class="bi bi-lightbulb bs-icon-small" ></i>
                                        <span class="ps-1">  Recommend Applicant </span>
                                    </a>
                                    <a  class="dropdown-item" v-if="!compRecommendationPhase && compActivePhase !== 'rejected' && compActivePhase !== 'talent_pool' && compActivePhase !== 'successful' && app.inform_status !== 'informed' "
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#actionModal"
                                        @click="changeApplicantProgress(app.id,'grade','Enter grade for '+app.full_name)"
                                    >
                                        <i class="bi bi-mortarboard-fill bs-icon-small" ></i>
                                        <span class="ps-1"> Grade </span>
                                    </a>
                                    <a  class="dropdown-item" v-if="app.step !== 'recommendation' && app.final_step_status === 'rejected' && app.inform_status !== 'informed' && compActivePhase !== 'talent_pool' && compActivePhase !== 'successful' "
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#actionModal"
                                        @click="changeApplicantProgress(app.id,'rejection_notification','Notify '+app.full_name+' of rejection')"
                                    >
                                        <i class="bi bi-bell-fill bs-icon-small" ></i>
                                        <span class="ps-1"> Rejection notification </span>
                                    </a>
                                    <a  class="dropdown-item" v-if="app.step !== 'recommendation' && app.step !== 'longlist' && app.final_step_status !== 'rejected' && app.inform_status !== 'informed' && compActivePhase !== 'talent_pool' && compActivePhase !== 'successful' "
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#actionModal"
                                        @click="changeApplicantProgress(app.id,'progress_notification','Notify '+app.full_name+' of progress')"
                                    >
                                        <i class="bi bi-bell-fill bs-icon-small" ></i>
                                        <span class="ps-1"> Progress notification </span>
                                    </a>
                                    <a  class="dropdown-item" v-if="app.step !== 'recommendation'  && app.inform_status !== 'informed' && compActivePhase === 'talent_pool' "
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#actionModal"
                                        @click="changeApplicantProgress(app.id,'talent_pool_notification','Notify '+app.full_name+' of addition to talent pool')"
                                    >
                                        <i class="bi bi-bell-fill bs-icon-small" ></i>
                                        <span class="ps-1"> Talent pool notification </span>
                                    </a>
                                    <a  class="dropdown-item" v-if="app.step !== 'recommendation'  && app.inform_status !== 'informed' && compActivePhase === 'successful' "
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#actionModal"
                                        @click="changeApplicantProgress(app.id,'success_notification','Notify '+app.full_name+' of success')"
                                    >
                                        <i class="bi bi-bell-fill bs-icon-small" ></i>
                                        <span class="ps-1"> Success notification </span>
                                    </a>
                                </div>
                            </div>
                        </small>
                    </div>
                </li>
            </ul>
            <div class="row p-5 m-0" style="background-color:#f5f5f5" v-else>
                <div class="text-center pt-5 pb-5 mt-5 mb-5">
                    <h1>
                        <i class="bi bi-search"></i>
                        No applicants found.
                    </h1>
                </div>
            </div>
    </div>
    <div class="row p-5 m-0" style="background-color:#f5f5f5" v-else>
        <div class="text-center pt-5 pb-5 mt-5 mb-5">
            <h1>
                <i class="bi bi-search"></i>
                No applicants found.
            </h1>
        </div>
    </div>



    <base-modal mId="actionModal"  mBg="bg-bage" mSize="lg"  >
         <template #header-left-slot>
             {{ requestTitle }}
         </template>

         <template #default>

             <create-applicant-progress :key="appId"
                                        :appDataSet="appDataSet"
                                        :actionType="actionType"
                                        :assessmentList="assessmentList"
              >
             </create-applicant-progress>

         </template>
    </base-modal>


    <base-modal mId="applicantModal"  mBg="bg-bage" mSize="lg"  :mTopPadding="false"  >
         <template #header-left-slot>

             <base-button
                 :btnType="applicantDataType === 'resume'?'':'flat' "
                 :btnColor="applicantDataType === 'resume'?'primary':'secondary' "
                 @click="changeApplicantDataType('resume')"
             >
                resume
             </base-button>
             <base-button
                 :btnType="applicantDataType === 'assessment'?'':'flat' "
                 :btnColor="applicantDataType === 'assessment'?'primary':'secondary' "
                 @click="changeApplicantDataType('assessment')"
             >
                assessment
             </base-button>
             <base-button
                 :btnType="applicantDataType === 'notes'?'':'flat' "
                 :btnColor="applicantDataType === 'notes'?'primary':'secondary' "
                 @click="changeApplicantDataType('notes')"
             >
                notes
             </base-button>

         </template>

         <template #default>

             <recruitment-applicant-profile
                :key="applicantDataId"
                :applicantData="applicantData"
                :applicantDataType="applicantDataType"
                :assessmentList="assessmentList"
                v-if="showApplicantData"
                >
             </recruitment-applicant-profile>

         </template>
    </base-modal>

</template>

<script>
import { useStore } from 'vuex';
import { ref , computed } from 'vue'
import CreateApplicantProgress from '@/components/forms/applicant/CreateApplicantProgress.vue';
import RecruitmentApplicantProfile from '@/components/recruitment/RecruitmentApplicantProfile.vue';
export default{
    emits:['emitSearchApplicant', 'emitSetupEvent'],
    props:{
        jobStatus:{
              type:String,
              default:"none"
        },
        jobTitle:{
            type:String,
            default:''
        },
        applicantTotal:{
            type:Number,
            default:0
        },
        applicantList:{
            type:Array,
            default:()=>[]
        },
        applicantSearchFilterList:{
            type:Array,
            default:()=>[]
        },
        assessmentList:{
            type:Object
        },
        activeFilter:{
            type:Boolean,
            default:false
        }
    },
    components:{
        CreateApplicantProgress,
        RecruitmentApplicantProfile
    },
    setup( props , { emit }){


        // get the global vuex store values
        const store = useStore();

        const isSelectAll = ref(false)
        const singleAction = ref(true)

        function toggleSingleAction(){
            let all_applicants = document.querySelectorAll('input[name="allApplicants[]"]:checked');
            singleAction.value = all_applicants.length > 0 ? false : true;

            if ( props.applicantList.length == all_applicants.length ){
                isSelectAll.value = true;
            }

            if ( all_applicants.length < 1){
                isSelectAll.value = false;
            }
        }

        function selectAllApplicants(){
            isSelectAll.value=!isSelectAll.value
            singleAction.value = isSelectAll.value?false:true;
        }



        const compFinalPhase = computed(function(){

            let activePhase = store.getters["applicant/getRecruitmentPhase"];

            let assessmentSize = Object.keys(props.assessmentList).length
            let assessmentProps = Object.getOwnPropertyNames(props.assessmentList)
            let finalPhase = assessmentProps[+assessmentSize - 3]


            return activePhase == finalPhase;
        })



        const compRecommendationPhase = computed(function(){

            let activePhase = store.getters["applicant/getRecruitmentPhase"];


            return activePhase == 'recommendation';
        })


        const compActivePhase = computed(function(){

            let activePhase = store.getters["applicant/getRecruitmentPhase"];

            return activePhase;
        })



        const appId=ref("")
        const appDataSet=ref([])
        const actionType=ref("")
        const requestTitle=ref("")

        function getRndInteger(min, max) {
          return Math.floor(Math.random() * (max - min) ) + min;
        }


        const applicantDataType = ref("")
        const showApplicantData = ref(false)
        const applicantData = ref({})
        const applicantDataId = ref("")
        function viewApplicantData(appId, app, dataType){
            applicantDataType.value = dataType;
            applicantData.value = app;
            applicantDataId.value = app.id;
            showApplicantData.value = true;
        }

        function changeApplicantDataType(dataType){
            applicantDataType.value = dataType
        }



        function changeApplicantProgress(id,action,title){

            appDataSet.value =[]

            let newApplicant = props.applicantList.filter((item) => item.id === id)

            appId.value = id + getRndInteger(1, 1000) + getRndInteger(1001, 10000);


            appDataSet.value.push({
                    id:id,
                    assessment:compActivePhase.value,
                    action:action,
                    job_title:props.jobTitle,
                    job_id:newApplicant[0].job_id,
                    org_id:newApplicant[0].org_client_id,
                    ind_id:newApplicant[0].ind_client_id,
                    recommendation:newApplicant[0].recommendation,
                    last_step:newApplicant[0].last_step
                })

            actionType.value = action
            requestTitle.value = title


        }

        const searchValue = ref("");
        function searchApplicant(){
            emit('emitSearchApplicant', searchValue.value)
        }


        const compRecruitmentStatus = computed(function(){

            return  (props.jobStatus === "cancel" || props.jobStatus === "complete") ? false : true;

        })



        return {
            isSelectAll,
            singleAction,
            toggleSingleAction,
            selectAllApplicants,
            appId,
            compFinalPhase,
            compRecommendationPhase,
            compActivePhase,
            appDataSet,
            actionType,
            requestTitle,
            changeApplicantProgress,
            searchApplicant,
            searchValue,
            compRecruitmentStatus,
            applicantDataType,
            applicantData,
            showApplicantData,
            applicantDataId,
            viewApplicantData,
            changeApplicantDataType
        }
    }
}
</script>

<style scoped>
/* #7367f0; */

ul{
    list-style:none;
    padding: 0rem;
    margin-bottom: 0rem;
}

ul li{
    padding:1rem;
    border-style:solid;
    border-color:#ebe9f1;
    border-width:0px 0px 1px 0px;
}




.search-bar-border{
  border-style:solid;
  border-color:#ebe9f1;
  border-width:0px 0px 1px 0px;
}

.search-bar-active{
    border-color:#7367f0;
}
</style>
