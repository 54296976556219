<template>
    <div class="modal modal-slide-in event-sidebar fade" id="add-new-sidebar">
        <div class="modal-dialog sidebar-lg">
            <div class="modal-content p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <div class="modal-header mb-1">
                    <h5 class="modal-title">{{ activeEventTitle }}</h5>
                </div>
                <div class="modal-body flex-grow-1 pb-sm-0 pb-3" v-if="activeEvent == 'filter'">
                    <form class="event-form needs-validation" data-ajax="false" novalidate>
                        <div class="mb-1 " v-if="filterList.activeFilter">
                                <div class="d-flex justify-content-between">
                                    <label for="select-label" class="form-label text-primary">
                                        Filter is active*
                                    </label>
                                    <span class="spinner-grow spinner-grow-sm text-primary me-1" role="status">
                                    </span>
                                </div>
                        </div>
                        <div class="mb-1">
                            <div class="d-flex justify-content-between">
                                <label for="select-label" class="form-label">
                                    Sort by filter question
                                </label>
                                <div class="form-check form-switch">
                                    <input type="checkbox" @click=" isFilterQuestions = !isFilterQuestions"
                                           class="form-check-input allDay-switch"
                                           id="customSwitch3"
                                    />
                                </div>
                            </div>
                            <div v-if="isFilterQuestions" class="p-1 bg-dirty-white rounded mt-1 mb-1"  >
                                <span v-for="(f,index) in JSON.parse(jobDetails.filters)" :key="index" >
                                    <label for="select-label" class="form-label mt-1">
                                        {{ f.question }}
                                    </label>
                                    <select class="select2 select-label form-select "
                                            :id="'filterAnswer'+index"
                                            @change="updateFilterQuestion"
                                     >
                                        <option  :value="'fq'+index+'::none'" >Select answer </option>
                                        <option  v-for="(r,index_sub) in f.responseOptions"
                                                 :value="'fq'+index+'::'+f.question.replace(/[^a-zA-Z0-9]/g,'_')+r.replace(/[^a-zA-Z0-9]/g,'_')"
                                                 :key="index_sub">
                                                  {{ r }}
                                        </option>
                                    </select>
                                </span>
                            </div>
                        </div>


                        <div class="mb-1">
                            <div class="d-flex justify-content-between">
                                <label for="select-label" class="form-label">
                                    Sort by personal details
                                </label>
                                <div class="form-check form-switch">
                                    <input type="checkbox" @click=" isFilterPersonalDetails = !isFilterPersonalDetails "
                                           class="form-check-input allDay-switch"
                                           id="customSwitch3" />
                                </div>
                            </div>
                            <div v-if="isFilterPersonalDetails" class="p-1 bg-dirty-white rounded mt-1 mb-1"  >
                                    <label for="select-label" class="form-label mt-1">
                                        Gender
                                    </label>
                                    <select class="select2 select-label form-select "
                                            @change="updateFilterGender"
                                     >
                                     <option value=""> Select gender </option>
                                     <option v-for="gen in genderList"
                                             :value="gen.value"
                                             :key="gen.value"
                                     >
                                         {{gen.alias}}
                                     </option>
                                    </select>

                                    <label for="select-label" class="form-label mt-1">
                                        Nationality
                                    </label>
                                    <select class="select2 select-label form-select "
                                            @change="updateFilterNationality"
                                     >
                                        <option  value="" >Select country </option>
                                        <option  v-for="(c,index) in countryList" :key="index"
                                                 :value="c.country_name"
                                        >
                                                  {{ c.country_alias }}
                                        </option>
                                    </select>
                            </div>
                        </div>

                        <div class="mb-1">
                            <div class="d-flex justify-content-between">
                                <label for="select-label" class="form-label">
                                    Sort by experience
                                </label>
                                <div class="form-check form-switch">
                                    <input type="checkbox" @click=" isFilterExperience = !isFilterExperience "
                                           class="form-check-input allDay-switch" id="customSwitch3" />
                                </div>
                            </div>
                            <div v-if="isFilterExperience" class="p-1 bg-dirty-white rounded mt-1 mb-1"  >
                                    <label for="select-label" class="form-label mt-1">
                                        Qualification
                                    </label>
                                    <select class="select2 select-label form-select "
                                            @change="updateFilterQualification"
                                     >
                                     <option value=""> Select qualification </option>
                                     <option v-for="q in qualificationTypes"
                                             :value="q.value"
                                             :key="q.value"
                                     >
                                         {{q.alias}}
                                     </option>
                                    </select>
                            </div>
                        </div>





                        <div class="mb-1 d-flex">
                            <button type="submit" class="btn btn-primary add-event-btn me-1">Sort using filters</button>
                        </div>
                    </form>

                </div>
                <div class="modal-body flex-grow-1 pb-sm-0 pb-3" v-if="activeEvent == 'finalize_recruitment'">

                    <div class="mb-1">
                        <div class="d-flex justify-content-between">
                            <label for="select-label" class="form-label">
                                <i class="bi bi-people font-medium-3"></i>
                                Total number of applicants : <b>{{ applicantList.length }}</b>
                            </label>
                        </div>
                    </div>

                    <div class="mb-1">
                        <div class="d-flex justify-content-between">
                            <label for="select-label" class="form-label">
                                <i class="bi bi-file-earmark-person font-medium-3"></i>
                                Applicants pending review : <b>{{ compApplicantPendingReview }}</b>
                            </label>
                        </div>
                    </div>

                    <div class="mb-1">
                        <div class="d-flex justify-content-between">
                            <label for="select-label" class="form-label">
                                <i class="bi bi-star-fill font-medium-3 text-success"></i>
                                Successful applicants :
                                <b>
                                    {{ compApplicantSuccessful.length }}
                                    <span class="summary-details-link rounded"
                                          :class=" showSuccessfulApplicants?'btn-success':'btn-outline-secondary' "
                                          v-if="compApplicantSuccessful.length>0"
                                          @click="showSuccessfulApplicants = !showSuccessfulApplicants"
                                    >
                                     {{ showSuccessfulApplicants?'hide':'view' }}
                                    </span>
                                </b>
                            </label>
                        </div>
                        <div class="p-1 bg-dirty-white rounded mt-1 mb-1"  v-if="showSuccessfulApplicants">
                                <label for="select-label" class="form-label mt-1">
                                    <ul>
                                        <li v-for="applicant in compApplicantSuccessful" :key="applicant.id">
                                            {{ applicant.full_name}}
                                        </li>
                                    </ul>
                                </label>
                        </div>
                    </div>

                    <div class="mb-1">
                        <div class="d-flex justify-content-between">
                            <label for="select-label" class="form-label ">
                                <i class="bi bi-patch-check-fill font-medium-3 text-primary"></i>
                                Nominated for talent pool :
                                <b>
                                    {{ compApplicantTalentPool.length }}
                                    <span class=" summary-details-link rounded"
                                          :class=" showTalentPoolApplicants?'btn-primary':'btn-outline-secondary' "
                                        @click="showTalentPoolApplicants = !showTalentPoolApplicants"
                                    >
                                     {{ showTalentPoolApplicants?'hide':'view' }}
                                    </span>
                                </b>
                            </label>
                        </div>
                        <div class="p-1 bg-dirty-white rounded mt-1 mb-1" v-if="showTalentPoolApplicants" >
                                <label for="select-label" class="form-label mt-1">
                                    <ul>
                                        <li v-for="applicant in compApplicantTalentPool" :key="applicant.id">
                                            {{ applicant.full_name}}
                                        </li>
                                    </ul>
                                </label>
                        </div>
                    </div>

                    <div class="mb-1 mt-4 text-danger">
                        <div class="d-flex justify-content-between">
                            <label for="select-label" class="form-label">
                                <h5  :class="finalizeOptionMsg[jobCurrentStatus]['type']">
                                    <i class="bi bi-megaphone"></i>
                                    Recruitment is <b>{{ finalizeOptionMsg[jobCurrentStatus]['msg'] }}</b>
                                </h5>
                            </label>
                        </div>
                    </div>


                    <div class="mb-1" v-if="jobCurrentStatus !== 'cancel' ">
                            <select class="select2 select-label form-select" @change="fv.reqFeedback.status=false" v-model="finalizeOption">
                                <option value="">Select finalization option </option>
                                <option value="complete" v-if="jobCurrentStatus !== 'complete'">Complete recruitment </option>
                                <option value="cancel" v-if="jobCurrentStatus !== 'complete'">Cancel recruitment </option>
                                <option value="ongoing" v-if="jobCurrentStatus === 'complete'"> Re-open recruitment </option>
                            </select>
                    </div>

                    <base-card-response :cardType="finalizeOption === 'complete' && !compIsCompleteRecruitment?'danger':'secondary'" v-if="finalizeOption">

                        <template #header> <i class="bi bi-exclamation-circle"></i> Important warning. </template>
                        <template #default>
                            <p v-if="finalizeOption === 'cancel'">
                                If you cancel this recruitment, you will not be able to continue with this recruitment again. To proceed, enter your password and Cancel.
                            </p>

                            <p v-if="finalizeOption === 'complete' && compIsCompleteRecruitment">
                                If you complete this recruitment, you won't be able to access this recruitment until it is reopened be able to continue with this recruitment again.
                                 To proceed, enter your password and Cancel
                            </p>

                            <p v-if="finalizeOption === 'complete' && !compIsCompleteRecruitment">
                                You can't complete this recruitment until you select at least 1 candidate for the role , or nominate them for the talent pool. To proceed, enter your password and complete.
                            </p>

                            <p v-if="finalizeOption === 'ongoing'">
                                Are you sure you wish to reopen this recruitment. To proceed, enter your password and Cancel.
                            </p>


                        </template>

                    </base-card-response>

                    <div class="col-12" v-if="fv.reqFeedback.status">

                        <base-list-tag class="mb-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>

                    <form class="auth-login-form mt-2" @submit.prevent="updateRecruitmentStatus" v-if="finalizeOption">

                        <div class="input-group input-group-merge form-password-toggle mb-1" >
                                    <input class="form-control form-control-merge" required id="fv_password"
                                     :type="fv.password.showPassword?'text':'password'" v-model="fv.password.val"
                                     :class="fv.password.msg?'border-danger':''"
                                     placeholder="Enter Password" @keyup="validate.validateFormData(fv,'password')"
                                     aria-describedby="password" min="8" max="32" tabindex="2" />
                                     <span class="input-group-text cursor-pointer" @click="togglePasswordVisibility">
                                        <i class="bi " :class="fv.password.showPassword?'bi-eye-slash-fill':'bi-eye-fill'"></i>
                                    </span>
                        </div>



                        <button type="submit" class="btn btn-primary w-100 add-event-btn mb-1" v-if="finalizeOption">
                            {{ finalizeOption == 'ongoing'?'Re-open':finalizeOption }} recruitment
                        </button>

                    </form>




                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref,reactive,inject,computed } from 'vue';
import { useStore } from 'vuex';
import validators from '@/hooks/validation'

export default {
    name: "RecruitmentApplicantEvent",
    emits: ['emitUpdateJobStatus'],
    props:{
        activeEvent:{
            type:String,
            default:"filter"
        },
        activeEventTitle:{
            type:String,
            default:"Filter applicants"
        },
        jobLinkId:{
              type:String,
              default:"none"
        },
        jobStatus:{
              type:String,
              default:"none"
        },
        jobDetails:{
            type:Object,
            default:()=>{}
        },
        applicantList:{
            type:Array,
            default:()=>[]
        },
    },
    setup(props, { emit } ){
        console.log(props.applicantList)
        // get the global vuex store values
        const store = useStore();

        // get the validators
        const validate = validators();

        // get the axios object
        const axios = inject('axios');  // inject axios

        //get the urls
        const coreUrl = inject('coreUrl');


        const isFilterQuestions = ref(false)
        const isFilterPersonalDetails = ref(false)
        const isFilterExperience = ref(false)
        const isFilterFee = ref(false)



        const filterList = reactive({
            activeFilter: false,
            questions: [],
            gender: false,
            country: false,
            qualification: false
        })

        const filterQuestionSet = ref([])

        function activateActiveFilter(){
            filterList.activeFilter = ( filterList.questions.length > 0 || filterList.nationality || filterList.gender || filterList.qualifications ) ? true : false;
        }

        function updateFilterQuestion(event){

            let filterValue = event.target.value
            let newFilterValue = filterValue.split('::')

            if(newFilterValue[1] != 'none'){
                let filterSet = {}

                filterSet[newFilterValue[0]] = newFilterValue[1]


                let noUpdate = true;
                for(let i = 0; i < filterQuestionSet.value.length; i++){



                    if(filterQuestionSet.value[i][newFilterValue[0]]){

                        filterQuestionSet.value[i][newFilterValue[0]] = newFilterValue[1]
                        noUpdate = false;
                    }
                }

                if(noUpdate){
                    filterQuestionSet.value.push(filterSet)
                }


            }else{


                filterQuestionSet.value = filterQuestionSet.value.filter((item) => !item[newFilterValue[0]] )
            }

            let actualQuestions = Array.from(filterQuestionSet.value, x => typeof x == 'string' ? x : Object.values(x)[0]);

            actualQuestions = [...new Set(actualQuestions)];

            filterList.questions = actualQuestions

            activateActiveFilter()


            // use the dispatch function to call an action that calls a mutation
            store.dispatch("applicant/executeSetApplicantFilters",filterList);

        }

        const countryList = ref(false);

        const params = { country_type: 'physical' }
        // use axios to get the data
        axios.get(
            coreUrl.backendUrl+'get_country_data',
            {withCredentials: true , params: params }).then((response) =>{

            const result = response.data.data;

            // use the dispatch function to call an action that calls a mutation
            // to set the data of for the job ad
            countryList.value = result


        }).catch((error)=>{
                console.log(error)
        })

        function updateFilterNationality(event){
            let nation = event.target.value;

            if(nation){
                filterList.country = nation
            }else{
                filterList.country = false;
            }

            // use the dispatch function to call an action that calls a mutation
            store.dispatch("applicant/executeSetApplicantFilters",filterList);

            activateActiveFilter()
        }

        const genderList = [
            {value:"male", alias:"Male"},
            {value:"female", alias:"Female"},
            {value:"anonymous", alias:"Prefer not to state"},
            {value:"other", alias:"Other"}
        ]

        function updateFilterGender(event){
            let gender = event.target.value;

            if(gender){
                filterList.gender = gender
            }else{
                filterList.gender = false;
            }

            // use the dispatch function to call an action that calls a mutation
            store.dispatch("applicant/executeSetApplicantFilters",filterList);

            activateActiveFilter()
        }


        const qualificationTypes = ref([
            {value:"doctorate",alias:"Doctorate"},
            {value:"masters",alias:"Masters"},
            {value:"bachelors",alias:"Bachelors"},
            {value:"higher_diploma",alias:"Higher Diploma"},
            {value:"certification",alias:"Certification"},
            {value:"license",alias:"License"},
            {value:"vocation",alias:"Vocation"}
        ]);


        function updateFilterQualification(event){
            let qualification = event.target.value;

            if(qualification){
                filterList.qualification = qualification
            }else{
                filterList.qualification = false;
            }

            // use the dispatch function to call an action that calls a mutation
            store.dispatch("applicant/executeSetApplicantFilters",filterList);

            activateActiveFilter()
        }

        /* Calculate the finalization of recruitment */

        const compApplicantPendingReview = computed(function(){


            let newApplicantList = props.applicantList.filter((item) => item.final_step_status === "review_pending");

            return newApplicantList.length;

        })

        const showSuccessfulApplicants = ref(false);


        const compApplicantSuccessful = computed(function(){


            let newApplicantList = props.applicantList.filter((item) => item.step === "successful");

            return newApplicantList;

        })

        const showTalentPoolApplicants = ref(false);

        const compApplicantTalentPool = computed(function(){


            let newApplicantList = props.applicantList.filter((item) => item.step === "talent_pool");

            return newApplicantList;

        })


        const compIsCompleteRecruitment = computed(function(){

            let totalQualified = compApplicantSuccessful.value.length + compApplicantTalentPool.value.length

            return totalQualified > 0 ? true:false;
        })


        const finalizeOption = ref("")
        const jobCurrentStatus = ref(props.jobStatus)


        // create variables for the values to be submitted
       const fv = reactive({
           password : {status:false, val:"", msg:false, showPassword: false, validate:["required",
                                                                                         {option:"textMin",val:10},
                                                                                         {option:"textMax",val:64}
                                                                                        ]},
           reqFeedback : {status:false, val:"", msg:false},
           showSubmit : {status:false, val:true}
       })

       // toggle password visibility
       function togglePasswordVisibility(){
           fv.password.showPassword = !fv.password.showPassword;
       }

       const finalizeOptionMsg = {
           cancel:{msg:"cancelled",type:'text-danger'},
           complete:{msg:"completed",type:'text-success'},
           ongoing:{msg:"opened",type:'text-secondary'}
       }

        // create the account using async
        async function updateRecruitmentStatus(){

            // end the action of making a submission, and hide loader
            fv.showSubmit.val = false;

            // load the form data , and append all the necessary fields
            let formData = new FormData();
            formData.append("fv_password", fv.password.val);
            formData.append("fv_publishStatus",finalizeOption.value);


            // post the data using axios
            try{
                const response = await axios.put(
                    coreUrl.backendUrl+'update_recruitment_status/'+props.jobLinkId,
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

                // If successful, get the resppnse data
                const responseData = response.data.data;
                console.log(responseData)


                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;
                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = `Recruitment was successfully ${finalizeOptionMsg[finalizeOption.value]['msg']}`
                fv.reqFeedback.val = "success";

                emit('emitUpdateJobStatus', finalizeOption.value)

                jobCurrentStatus.value = finalizeOption.value
                finalizeOption.value = ""


            }catch(error){

                console.log(error)
                // load the error to the appropriate field
                const errorMessages = error.response.data.message;

                for(const key in errorMessages){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = error.response.data.message[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = errorMessages["reqFeedback"]?errorMessages["reqFeedback"]:"Account cannot be verified at this time.";
                fv.reqFeedback.val = "danger";
                fv.showSubmit.status = false;
                // end load the error to the appropriate field

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;

            }

        }







        return {
            validate,
            isFilterQuestions,
            isFilterPersonalDetails,
            isFilterExperience,
            isFilterFee,
            filterList,
            updateFilterQuestion,
            updateFilterGender,
            updateFilterNationality,
            countryList,
            genderList,
            qualificationTypes,
            updateFilterQualification,
            compApplicantPendingReview,
            compApplicantSuccessful,
            compApplicantTalentPool,
            showSuccessfulApplicants,
            showTalentPoolApplicants,
            compIsCompleteRecruitment,
            finalizeOption,
            updateRecruitmentStatus,
            togglePasswordVisibility,
            fv,
            jobCurrentStatus,
            finalizeOptionMsg
        }
    }
}
</script>


<style scoped>
.summary-details-link{
    padding:3px;
}
</style>
